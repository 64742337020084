import './Entraide.scss';
import partnerImage from '../../../../public/css/icon/Groupe 34.png'
import partnerImage2 from '../../../../public/css/icon/Groupe 342.png'

import React, {Component} from 'react';


class Entraide extends Component {


    render() {
        // const loading = this.state.loading;
        return (
            <>
            <div className="entraide">
                <a href="https://www.entraide.ch/fr">
                    <img className="partner-imag" src={partnerImage}/>
                    <img className="partner-imag" src={partnerImage2}/>
                </a>
            </div>
                </>
        )
    }
}

export default Entraide;